<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Banner
        <div class="card-header-actions">
          <a class="card-header-action" href="mobile-banner" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="titleInputGroup1"
                            label="Title"
                            label-for="title">
                <b-form-input id="title"
                              type="text"
                              v-model.lazy.trim="$v.form.title.$model"
                              :state="chkState('title')"
                              aria-describedby="titleFeedback"
                              placeholder="Please Enter Banner Title"
                              autofocus />
                <b-form-invalid-feedback id="titleFeedback" v-for="(error , index) in errors.form.title" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="contentInputGroup2"
                            label="Content"
                            label-for="content">
                <quill-editor id="content"
                              style="margin-bottom:5px"
                              v-model.lazy.trim="$v.form.content.$model"
                              :state="chkState('content')"
                              autocomplete='family-name'>
                </quill-editor>
                <div  v-if="this.form.content == '' " >
                  <p id="contentFeedback" v-for="(error , index) in errors.form.content" :key="index">
                    - {{ error }} <br>
                  </p>
                </div>
              </b-form-group>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="selectedImage"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model.lazy.trim="$v.form.selectedImage.$model"
                              :state="chkState('selectedImage')"
                              @change="onFileSelected">
                </b-form-file>
                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="typeInputGroup1"
                            label="Category"
                            label-for="category">
                <b-form-select id="category"
                              :plain="true"
                              v-model.lazy.trim="$v.form.category.$model"
                              :state="chkState('category')"
                              :options="[{value: '', text:'Please select'},
                                                {value: 'Product', text: 'Product'},
                                                {value: 'Article', text: 'Promo/News/Article/Coupon'},
                                                {value: 'Main', text: 'Main'},
                                                {value: 'BottomSheet', text: 'BottomSheet'}]"
                              @change="relatedContent(form.category)"
                              aria-describedby="categoryFeedback"
                              autofocus />
                <b-form-invalid-feedback id="categoryFeedback" v-for="(error , index) in errors.form.category" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="related_select"
                            v-if="form.category === 'Main'"
                            label="Related Content"
                            label-for="related_select">
                <b-form-select id="related_select"
                              :plain="true"
                              v-model.lazy.trim="$v.form.related_select.$model"
                              :state="chkState('related_select')"
                              :options="[{value: '', text:'Choose Related Content'},
                                          {value: 'Article', text: 'Article'},
                                          {value: 'Link', text: 'Link'}]"
                              aria-describedby="relatedSelectFeedback"
                              autofocus />
                <b-form-invalid-feedback id="relatedSelectFeedback" v-for="(error , index) in errors.form.related" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
            <b-form-group id="relatedInput"
                            v-if="form.category === 'Main' && form.related_select === 'Article'"
                            label="Article"
                            label-for="related">
                <b-form-select id="related"
                              :plain="true"
                              v-model.lazy.trim="$v.form.related.$model"
                              :state="chkState('related')"
                              aria-describedby="relatedFeedback"
                              autofocus >
                    <option value="">Choose Article</option>
                    <option v-for="(category, key) in related" :key="key" :value="category.id">- {{ form.category == 'Product' ? category.name : category.title }}</option>
                </b-form-select>
                <b-form-invalid-feedback id="relatedFeedback" v-for="(error , index) in errors.form.related" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="relatedInput"
                            v-if="form.category !== 'Main'"
                            label="Related Content"
                            label-for="related">
                <b-form-select id="related"
                              :plain="true"
                              v-model.lazy.trim="$v.form.related.$model"
                              :state="chkState('related')"
                              aria-describedby="relatedFeedback"
                              autofocus >
                    <option value="">Choose Related Content</option>
                    <option v-for="(category, key) in related" :key="key" :value="category.id">- {{ form.category == 'Product' ? category.name : category.title }}</option>
                </b-form-select>
                <b-form-invalid-feedback id="relatedFeedback" v-for="(error , index) in errors.form.related" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="link"
                            v-if="form.category === 'Main' && form.related_select === 'Link'"
                            label="Link"
                            label-for="link">
                <b-form-input id="link"
                              type="text"
                              v-model.lazy.trim="$v.form.link.$model"
                              :state="chkState('link')"
                              aria-describedby="linkFeedback"
                              placeholder="Please Enter Link"
                              autofocus />
                <b-form-invalid-feedback id="linkFeedback" v-for="(error , index) in errors.form.related" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="position"
                              label="Position"
                              label-for="position">
                  <b-form-input id="position"
                              type="number"
                              v-model.lazy.trim="$v.form.position.$model"
                              :state="chkState('position')"
                              aria-describedby="positionFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                  <b-form-invalid-feedback id="positionFeedback" >
                  <span v-if="!$v.form.position.numeric">- Position harus berupa angka valid.</span>
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, numeric } from "vuelidate/lib/validators"

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        title: '',
        content: '',
        category: '',
        selectedImage: '',
        related: '',
        position: '1',
        related_select: '',
        link: ''
      },
      related: [],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          title: [],
          content: [],
          image: [],
          related_select: [],
          category: [],
          related: [],
          link: []
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
      },
      category: {
        required
      },
      related: {
        required
      },
      content: {
        required
      },
      selectedImage: {
        required
      },
      related_select: {
        required
      },
      link: {
        required
      },
      position: {
        numeric
      }
    }
  },
  methods: {
      relatedContent (value) {
          if (value == 'Product') {
              return new Promise((resolve, reject) => {
                this.$http
                    .post(`category-banners`, {
                        category: value
                    })
                    .then((result) => {
                        this.related = result.data.meta.data
                        resolve(result.data)
                    })
                    .catch((error) => {
                      if (error.response) {
                        this.errors.code = error.response.status;
                        this.errors.status = error.response.data.meta.code;
                        this.errors.headers = error.response.headers;
                      }
                        reject(error)
                    })
              })
          }else{
            return new Promise((resolve, reject) => {
                this.$http
                    .post(`category-banners`, {
                        category: value
                    })
                    .then((result) => {
                        this.related = result.data.meta.data
                        resolve(result.data)
                    })
                    .catch((error) => {
                        if (error.response) {
                          this.errors.code = error.response.status;
                          this.errors.status = error.response.data.meta.code;
                          this.errors.headers = error.response.headers;
                        }
                        reject(error)
                    })
            })
          }
      },
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    onSubmit() {
        this.validate()
        this.isLoading = true
          const img =  new FormData();
          if (this.form.selectedImage == '') {
            img.append('image', this.form.selectedImage = '')
            img.append('title', this.form.title)
            img.append('content', this.form.content)
            img.append('category', this.form.category)
            img.append('type_id', this.form.category === 'Main' && this.form.related_select === 'Link' ? this.form.link : this.form.related)
            img.append('position', this.form.position)
          }else{
            img.append('image', this.form.selectedImage, this.form.selectedImage.name)
            img.append('title', this.form.title)
            img.append('content', this.form.content)
            img.append('category', this.form.category)
            img.append('type_id', this.form.category === 'Main' && this.form.related_select === 'Link' ? this.form.link : this.form.related)
            img.append('position', this.form.position)
          }
          console.log(this.form);
          this.$http.post(`store-banner`, img)
          .then(() => {
            this.isLoading = false
            this.$router.push("/mobile-banner");
            this.$toasted.success('Banner successfully created!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.image = this.errors.message.image;
                this.errors.form.category = this.errors.message.category;
                this.errors.form.related = this.errors.message.type_id;
              }else if (this.errors.status == 404) {
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message,
                  'error'
                ).then(() =>{
                  location.reload()
                })
              }else if (this.errors.status == 400) {
                this.$swal.fire(
                  'Failed!',
                  error.response.data.meta.message,
                  'error'
                )
              }
            }
          })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
.validate {
  border: green 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}

</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
